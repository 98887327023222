@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    font-family: 'Poppins', sans-serif;
}

body {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    background: rgb(25, 69, 71);
    background: radial-gradient(circle, rgba(21, 32, 43, 1) 100%, rgba(25, 69, 71, 1) 50%);
    background-attachment: fixed;
    scroll-behavior: smooth;

}

button {
    outline: 0;
    border: 0;
    transition: all 0.1s ease-out;
}

button:hover {
    transform: scale(1.01);
}

button:disabled {
    cursor: default;
}

button:disabled:hover {
    transform: none;
}

input {
    background: none;
    border: 0;
    outline: 0;
}

input:focus, textarea:focus {
    outline: 0;
    border: 0;
}

input::placeholder {
    color: #ffffff;
}

textarea {
    resize: none;
}

* {
    scrollbar-color: #f1f1f1 #15202B;
}

::-webkit-scrollbar {
    background: #15202B;
    width: 6px;
}

::-webkit-scrollbar-thumb {
    background: #f1f1f1;
    border-radius: 20px;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Poppins', sans-serif;
    color: #f1f1f1;
}

a {
    text-decoration: none;
    color: inherit;
}

a:hover {
    /* text-decoration: underline; */
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    color: #f1f1f1;
}